import { SUSPENSE } from '@react-rxjs/core'
import {
  map,
  MonoTypeOperatorFunction,
  ObservableInput,
  ObservedValueOf,
  of,
  OperatorFunction,
  switchMap,
  tap,
} from 'rxjs'

/**
 * Projects each source value to an Observable when Result/Either is `Right`
 */
export const switchMapRight = <
  T extends { _tag: 'Left' | 'Right' },
  O extends ObservableInput<unknown>,
>(
  project: (value: Extract<T, { _tag: 'Right' }>, index: number) => O,
): OperatorFunction<T, Extract<T, { _tag: 'Left' }> | ObservedValueOf<O>> =>
  switchMap((value, index) =>
    value._tag === 'Left'
      ? of(value as Extract<T, { _tag: 'Left' }>)
      : project(value as Extract<T, { _tag: 'Right' }>, index),
  )

/**
 * Projects each source value to an Observable when Result/Either is `Left`
 */
export const switchMapLeft = <
  T extends { _tag: 'Left' | 'Right' },
  O extends ObservableInput<unknown>,
>(
  project: (value: Extract<T, { _tag: 'Left' }>, index: number) => O,
): OperatorFunction<T, Extract<T, { _tag: 'Right' }> | ObservedValueOf<O>> =>
  switchMap((value, index) =>
    value._tag === 'Left'
      ? project(value as Extract<T, { _tag: 'Left' }>, index)
      : of(value as Extract<T, { _tag: 'Right' }>),
  )

/**
 * Applies a given `project` function to each value emitted by the source Observable
 * when emitted Result/Either is `Right`
 */
export const mapRight = <T extends { _tag: 'Left' | 'Right' }, R>(
  project: (value: Extract<T, { _tag: 'Right' }>, index: number) => R,
): OperatorFunction<T, Extract<T, { _tag: 'Left' }> | R> =>
  map((value, index) =>
    value._tag === 'Left'
      ? (value as Extract<T, { _tag: 'Left' }>)
      : project(value as Extract<T, { _tag: 'Right' }>, index),
  )

/**
 * Perform side effect without altering notification when emitted Result/Either is `Right`
 */
export const tapRight = <T extends { _tag: 'Left' | 'Right' }>(
  next: (value: Extract<T, { _tag: 'Right' }>) => void,
): MonoTypeOperatorFunction<T> =>
  tap((value) => (value._tag === 'Left' ? void 0 : next(value as Extract<T, { _tag: 'Right' }>)))

/**
 * Perform side effect without altering notification when emitted Result/Either is `Left`
 */
export const tapLeft = <T extends { _tag: 'Left' | 'Right' }>(
  next: (value: Extract<T, { _tag: 'Left' }>) => void,
): MonoTypeOperatorFunction<T> =>
  tap((value) => (value._tag === 'Right' ? void 0 : next(value as Extract<T, { _tag: 'Left' }>)))

/**
 * Applies a given `project` function to each value emitted by the source Observable
 * when emitted Result/Either is `Right`, and re-emits SUSPENSE if suspended
 */
export const mapSuspended = <T, R>(
  project: (value: T, index: number) => R,
): OperatorFunction<T | typeof SUSPENSE, R | typeof SUSPENSE> =>
  map((value, index) => (value === SUSPENSE ? SUSPENSE : project(value, index)))

/**
 * Applies a given `project` function to each value emitted by the source Observable
 * when emitted Result/Either is `Right`, and re-emits SUSPENSE if suspended
 */
export const mapRightSuspended = <T extends { _tag: 'Left' | 'Right' }, R>(
  project: (value: Extract<T, { _tag: 'Right' }>, index: number) => R,
): OperatorFunction<T | typeof SUSPENSE, Extract<T, { _tag: 'Left' }> | R | typeof SUSPENSE> =>
  mapSuspended((value, index) =>
    value._tag === 'Left'
      ? (value as Extract<T, { _tag: 'Left' }>)
      : project(value as Extract<T, { _tag: 'Right' }>, index),
  )

/**
 * Applies a given `project` function to each value emitted by the source Observable
 * when emitted Result/Either is `Right`, and re-emits SUSPENSE if suspended
 */
export const switchMapSuspended = <T, O extends ObservableInput<unknown>>(
  project: (value: T, index: number) => O,
): OperatorFunction<T | typeof SUSPENSE, ObservedValueOf<O> | typeof SUSPENSE> =>
  switchMap((value, index) => (value === SUSPENSE ? of(SUSPENSE) : project(value, index)))
